import React, { useContext } from 'react'
import { node, number, shape } from 'prop-types'
import { Pie, PieChart } from 'recharts'

import LangContext from 'context/LangContext'

import Card from 'components/card'

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, name }) => {
  const centerX = cx
  const centerY = cy
  const RADIAN = Math.PI / 180
  const radius = innerRadius + (outerRadius - innerRadius) * 2

  const labelXPosition = centerX + radius * Math.cos(-midAngle * RADIAN)
  const labelYPosition = centerY + radius * Math.sin(-midAngle * RADIAN)

  if (name === 'Group B' && percent > 0) {
    const adjustedYPosition = (yOffset) => (labelYPosition < centerY ? labelYPosition - yOffset : labelYPosition)
    const textAnchor = labelXPosition > centerX ? 'start' : labelXPosition === centerX ? 'middle' : 'end'

    return (
      <g>
        <text
          x={labelXPosition}
          y={adjustedYPosition(20)}
          textAnchor={textAnchor}
          dominantBaseline="central"
          fill="#0f172a"
          fontSize={16}
          fontWeight={500}
        >
          50 ctn
        </text>
        <text
          x={labelXPosition}
          y={adjustedYPosition(0)}
          textAnchor={textAnchor}
          dominantBaseline="central"
          fill="#0f172a"
          fillOpacity={0.6}
          fontSize={14}
          fontWeight={500}
        >
          {`+${(percent * 100).toFixed(0)}%`}
        </text>
      </g>
    )
  }
}

const Graph = () => {
  const { translate } = useContext(LangContext)

  return (
    <div className="relative flex items-center justify-center">
      <PieChart width={300} height={300} margin={{ top: 64, right: 64, bottom: 64, left: 64 }}>
        <Pie
          data={[
            { name: 'Group A', value: 100, fill: '#0ea5e9' },
            { name: 'Group B', value: 200, fill: '#7dd3fc' },
            { name: 'Group C', value: 400, fill: '#f1f5f9' }
          ]}
          startAngle={270}
          endAngle={-90}
          dataKey="value"
          innerRadius="80%"
          outerRadius="100%"
          isAnimationActive={false}
          labelLine={false}
          label={renderCustomizedLabel}
        />
      </PieChart>
      <div className="absolute left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2 flex-col items-center">
        <span className="block text-center text-2xs font-medium text-slate-500">{translate('common.actuals')}</span>
        <span className="block min-w-fit text-center text-xl font-medium text-slate-900">35.0%</span>
        <span className="block text-center text-2xs font-medium text-slate-500">{translate('common.ofTarget')}</span>
      </div>
    </div>
  )
}

const DataLabel = ({ children }) => <span className="font-semibold text-slate-900">{children}</span>

DataLabel.propTypes = {
  children: node
}

const AddedValueCard = ({ span }) => {
  const { translate } = useContext(LangContext)

  return (
    <Card title={translate('evaluate.addedValue')} span={span} displayAmplify={false}>
      <div className="grid grid-cols-6">
        <div className="col-span-3 row-start-2 flex w-full flex-col items-center justify-center gap-2 @xl/card:col-span-1 @xl/card:row-start-1 @4xl/card:col-span-2">
          <span className="block text-center text-2xs font-medium text-slate-500">{translate('common.actuals')}</span>
          <span className="block min-w-fit text-center text-xl font-medium text-slate-900">105 ctn</span>
        </div>
        <div className="col-span-6 shrink-0 @xl/card:col-span-4 @4xl/card:col-span-2">
          <Graph />
        </div>
        <div className="col-span-3 flex w-full flex-col items-center justify-center gap-2 @xl/card:col-span-1 @4xl/card:col-span-2">
          <span className="block text-center text-2xs font-medium text-slate-500">{translate('common.gap')}</span>
          <span className="block min-w-fit text-center text-xl font-medium text-slate-900">-195 ctn</span>
        </div>
      </div>

      <hr className="my-2" />

      <ul className="flex list-disc flex-col gap-2 pl-5 text-slate-700">
        <li>
          {translate('evaluate.addedValue.cycleTargetCompletion.template', {
            date: 'OCT 15th 2023',
            completion: '35.0%'
          })}
        </li>
        <li>
          {translate('evaluate.addedValue.closingGapHeader')}
          <ul className="list-inside list-disc gap-1 pl-3">
            <li>
              {translate('evaluate.addedValue.closingGap.gapPercentage.template', {
                gap: '+16.7%'
              })}
            </li>
            <li>
              {translate('evaluate.addedValue.closingGap.gapValue.template', {
                gapValue: '-95 ctn'
              })}
            </li>
          </ul>
        </li>
        <li>
          {translate('evaluate.addedValue.nextOrderDay.template', {
            date1: '2023-10-24',
            date2: '2023-10-31'
          })}
        </li>
        <li>
          {translate('evaluate.addedValue.message.template', {
            contribution: '$1,200.00 NTO'
          })}
        </li>
      </ul>

      <span className="mt-auto text-2xs text-slate-500">{translate('evaluate.addedValue.disclaimer')}</span>
    </Card>
  )
}

AddedValueCard.propTypes = {
  span: shape({
    xs: number,
    sm: number,
    md: number,
    lg: number,
    xl: number
  })
}

export default AddedValueCard
