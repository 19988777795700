import React, { createContext, useState } from 'react'
import { node } from 'prop-types'

const SellinContext = createContext()
export default SellinContext

export const SellinProvider = ({ children }) => {
  const [vapeCategory, setVapeCategory] = useState('all')
  const [unitOfMeasure, setUnitOfMeasure] = useState('ceq')

  const value = {
    vapeCategory,
    setVapeCategory,
    unitOfMeasure,
    setUnitOfMeasure
  }

  return <SellinContext.Provider value={value}>{children}</SellinContext.Provider>
}

SellinProvider.propTypes = {
  children: node.isRequired
}
