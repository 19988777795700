import React, { useContext, useState } from 'react'
import { number, shape } from 'prop-types'

import LangContext from 'context/LangContext'

import Button from 'components/button/Button'
import Card from 'components/card'
import Checklist from 'components/evaluate/Checklist'
import ItemWithRating from 'components/evaluate/ItemWithRating'
import Input from 'components/Input'
import { Modal } from 'components/Modal'

const ObjectivesCard = ({ span }) => {
  const { translate } = useContext(LangContext)

  const initializeRatings = () => {
    const labels = ['Sell In', 'Sell Out', 'Distro', 'Price', 'Cycle Focus', 'Product Recommendation']
    return labels.map((label) => ({ label, rating: null }))
  }

  const [performance, setPerformance] = useState(initializeRatings())

  const handleRating = (label, newRating) => {
    setPerformance((prevPerformance) =>
      prevPerformance.map((item) =>
        item.label === label ? { ...item, rating: item.rating === newRating ? null : newRating } : item
      )
    )
  }

  const [objectives, setObjectives] = useState([
    {
      header: 'Did you align the price strategies in this store?',
      items: [
        { label: 'Align Du Maurier price to Belmont + 0.50c', checked: false },
        { label: 'Pall Mall EDLP', checked: false },
        { label: 'Align VUSE to ceiling price', checked: false }
      ]
    },
    {
      header: 'Did you complete the trade activities?',
      items: [
        { label: 'Sell-In for Marlboro Plus', checked: false },
        { label: 'Complete survey', checked: false }
      ]
    }
  ])

  const handleObjectivesCheck = (header, updatedItems) => {
    setObjectives((prevObjectives) =>
      prevObjectives.map((objective) =>
        objective.header === header ? { ...objective, items: updatedItems } : objective
      )
    )
  }

  const [reminders, setReminders] = useState([
    {
      header: 'Did you follow up on your reminders?',
      items: [
        { label: 'Clerk education Hestia and Modi 2.0', checked: false },
        { label: 'Support VUSE return', checked: false },
        { label: 'Support place order via Emergency Order', checked: false },
        { label: 'Clerk education Hestia and Modi 2.0', checked: false },
        { label: 'Support VUSE return', checked: false },
        { label: 'Support place order via Emergency Order', checked: false },
        { label: 'Clerk education Hestia and Modi 2.0', checked: false },
        { label: 'Support VUSE return', checked: false },
        { label: 'Support place order via Emergency Order', checked: false },
        { label: 'Clerk education Hestia and Modi 2.0', checked: false },
        { label: 'Support VUSE return', checked: false },
        { label: 'Support place order via Emergency Order', checked: false }
      ]
    }
  ])

  const handleRemindersCheck = (header, updatedItems) => {
    setReminders((prevReminders) =>
      prevReminders.map((reminder) => (reminder.header === header ? { ...reminder, items: updatedItems } : reminder))
    )
  }

  const [isReminderModalOpen, setIsReminderModalOpen] = useState(false)
  const [temporaryReminder, setTemporaryReminder] = useState('')

  const handleReminderChange = (e) => {
    setTemporaryReminder(e.target.value)
  }

  const handleAddReminder = () => {
    if (temporaryReminder.trim()) {
      setReminders((prevReminders) => {
        const updatedReminders = [...prevReminders]

        if (updatedReminders.length > 0) {
          updatedReminders[0].items.push({ label: temporaryReminder.trim(), checked: false })
        } else {
          updatedReminders.push({
            header: 'Did you follow up on your reminders?',
            items: [{ label: temporaryReminder.trim(), checked: false }]
          })
        }

        return updatedReminders
      })

      setTemporaryReminder('')

      setIsReminderModalOpen(false)
    }
  }

  return (
    <Card title={translate('evaluate.objectives')} span={span} displayAmplify={false}>
      {/* Performance */}
      <div className="flex flex-col gap-4">
        <h3 className="text-lg font-medium leading-snug">{translate('evaluate.objectives.performance')}</h3>
        <div className="flex flex-col gap-3 pl-4">
          {performance.map((item) => (
            <ItemWithRating
              key={item.label}
              item={item.label}
              rating={item.rating}
              handleRating={(newRating) => handleRating(item.label, newRating)}
            />
          ))}
        </div>
      </div>

      <hr className="border-slate-200" />

      {/* Objectives */}
      <div className="flex flex-col gap-6">
        <h3 className="text-lg font-medium leading-snug">{translate('evaluate.objectives.todaysObjectives')}</h3>
        <div className="flex flex-col gap-5">
          {objectives.map((objective) => (
            <Checklist
              key={objective.header}
              header={objective.header}
              items={objective.items}
              handleCheck={(updatedItems) => handleObjectivesCheck(objective.header, updatedItems)}
            />
          ))}
        </div>
      </div>

      <hr className="border-slate-200" />

      {/* Reminders */}
      <div className="flex flex-col gap-6">
        <h3 className="text-lg font-medium leading-snug">{translate('evaluate.objectives.reminders')}</h3>
        <div className="flex flex-col gap-3">
          {reminders.map((reminder) => (
            <Checklist
              key={reminder.header}
              header={reminder.header}
              items={reminder.items}
              handleCheck={(updatedItems) => handleRemindersCheck(reminder.header, updatedItems)}
            />
          ))}
        </div>

        <div>
          <Modal
            title={translate('evaluate.objectives.newReminder.title')}
            subtitle={translate('evaluate.objectives.newReminder.subtitle')}
            trigger={
              <Button onClick={() => setIsReminderModalOpen(true)} primary>
                {translate('common.newReminder')}
              </Button>
            }
            open={isReminderModalOpen}
            onOpenChange={setIsReminderModalOpen}
            onHandeClose={() => setIsReminderModalOpen(false)}
            footer={
              <div className="flex gap-2">
                <Button secondary onClick={() => setIsReminderModalOpen(false)}>
                  {translate('common.cancel')}
                </Button>
                <Button primary onClick={handleAddReminder}>
                  {translate('common.addReminder')}
                </Button>
              </div>
            }
          >
            <Input
              value={temporaryReminder}
              onChange={handleReminderChange}
              placeholder={translate('common.reminderPlaceholder')}
            />
          </Modal>
        </div>
      </div>
    </Card>
  )
}

ObjectivesCard.propTypes = {
  span: shape({
    xs: number,
    sm: number,
    md: number,
    lg: number,
    xl: number
  })
}

export default ObjectivesCard
