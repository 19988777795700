import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { Navigate, useParams } from 'react-router-dom'
import { countBy, flattenDeep, groupBy, sortBy, startCase, sumBy } from 'lodash'
import { arrayOf, func, object, string } from 'prop-types'
import styled from 'styled-components'

import ActionHeaderContext from 'context/ActionHeaderContext'
import LangContext from 'context/LangContext'
import SectorContext from 'context/SectorContext'

import { isDataKeyLoading } from 'store/dataFetches/selectors'
import { getCurrentTerritory } from 'store/sector/selectors'
import { fetchSellInProgram } from 'store/sellInPrograms/actions'
import { sendSellInTerritoryRetailerEmail } from 'store/sellInPrograms/endpoints'
import * as sellInSelector from 'store/sellInPrograms/selectors'

import BasicAccordion from 'components/accordion/BasicAccordion'
import Button from 'components/button/Button'
import ButtonGroup from 'components/button/ButtonGroup'
import ConfirmActionSheet from 'components/ConfirmActionSheet'
import Container from 'components/Container'
import CustomerAddress from 'components/CustomerAddress'
import Dropdown from 'components/Dropdown'
import Input from 'components/Input'
import LoadingCard from 'components/LoadingCard'
import Pill from 'components/Pill'
import SectionDivider from 'components/SectionDivider'
import BrandTable from 'components/sellInPrograms/BrandTable'
import ExceptionTable from 'components/sellInPrograms/ExceptionTable'
import SellInEndedAlert, { isSellInEnded } from 'components/sellInPrograms/SellInEndedAlert'
import TerritoryTargetsTable from 'components/sellInPrograms/TerritoryTargetsTable'
import StatsDash from 'components/StatsDash'
import Table from 'components/table'

import { DATAKEY_TYPES, SECTOR_LEVELS } from 'utils/constants'
import { downloadSellInProgramSkuData } from 'utils/fileDownloads'
import { formatNumber } from 'utils/formatters'
import { createDataKey } from 'utils/helpers'

import { sellInStatusColors } from 'styles/colors'
import { media } from 'styles/media'
import * as spacing from 'styles/spacing'

import SellInExceptionModal from './SellInCustomerView/SellInExceptionModal'

// Should probably be it's own component
const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.small};
  margin: ${spacing.medium} ${spacing.tiny};
  margin-bottom: ${spacing.tiny};

  ${media.breakpoint`
    margin: ${spacing.medium} 0;
    margin-bottom: ${spacing.tiny};
  `};
`

const StyledInput = styled(Input)`
  flex: 0 1 60%;
`

const StyledSelect = styled(Dropdown)`
  flex: 0 1 40%;
`

const TextFilter = ({ filterOptions = [], onFilterChange, onInputChange, filterValue, placeholder }) => {
  return (
    <FilterContainer>
      <StyledInput icon="search" onChange={onInputChange} small placeholder={placeholder} />
      <StyledSelect
        placeholder="All statuses"
        options={filterOptions}
        onChange={onFilterChange}
        value={filterValue}
        placeholderIsValid
        small
      />
    </FilterContainer>
  )
}
TextFilter.propTypes = {
  filterOptions: arrayOf(object).isRequired,
  onFilterChange: func.isRequired,
  onInputChange: func.isRequired,
  filterValue: string,
  placeholder: string
}

const StoresView = ({ connectedFetchSellInProgram }) => {
  const [loading, setLoading] = useState(true)
  const [downloading, setDownloading] = useState(false)
  const [emailSending, setEmailSending] = useState(false)
  const [storeInputValue, setStoreInputValue] = useState()
  const [storesFilterValue, setStoresFilterValue] = useState('')
  const [exceptionInputValue, setExceptionInputValue] = useState()
  const [exceptionsFilterValue, setExceptionsFilterValue] = useState('')
  const [showConfirmEmailSend, setShowConfirmEmailSend] = useState(false)

  const { programId: sellInProgramId, sectorId: territoryId } = useParams()
  const { fetchSector } = useContext(SectorContext)

  const currentTerritory = useSelector((state) => getCurrentTerritory(state, { territoryId }))
  const sellInProgram = useSelector((state) =>
    sellInSelector.fullSellInProgram(state, { sellInProgramId, territoryId })
  )
  const sellInBrandTotals = useSelector((state) =>
    sellInSelector.sellInProgramBrandTotals(state, { sellInProgramId, territoryId })
  )
  const isVolumeProgram = useSelector((state) =>
    sellInSelector.isVolumeSellInProgram(state, { sellInProgramId, territoryId })
  )

  const { translate } = useContext(LangContext)

  const isMounted = useRef()
  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  const programDataKey = createDataKey(DATAKEY_TYPES.TERRITORY_SELL_IN_PROGRAM, { territoryId, sellInProgramId })
  const isFetching = useSelector((state) => isDataKeyLoading(state, { dataKey: programDataKey }))
  const isLoading = isFetching || loading
  useEffect(() => {
    if (territoryId) {
      setLoading(true)
      connectedFetchSellInProgram(sellInProgramId, territoryId, null).then(() => {
        if (isMounted.current) setLoading(false)
      })
    }
  }, [sellInProgramId, territoryId])

  const { addAction } = useContext(ActionHeaderContext)
  useEffect(() => {
    addAction({
      getSellInProgramName: () => sellInProgram?.name || sellInProgram?.id || ''
    })
  }, [sellInProgram?.name])

  const formattedTargets = useMemo(() => {
    if (!sellInProgram?.targets?.length) return []
    return sellInProgram.targets
      .filter((target) => sellInProgram.targetsByTerritory[territoryId]?.find((tbt) => tbt === target.customerId))
      .map(({ skus = [], ...rest }) => {
        const suggestedQty = sumBy(skus, 'suggestedQty')
        const finalQty = rest.status === 'accepted' ? sumBy(skus, 'finalQty') : null
        return {
          ...rest,
          suggestedQty,
          finalQty
        }
      })
  }, [sellInProgram?.targets, territoryId])

  const countsByStatus = countBy(formattedTargets, 'status')
  const storesTargeted = formattedTargets.length

  const { pending: exceptionsPending = 0 } = sellInProgram?.exceptions?.length
    ? countBy(sellInProgram?.exceptions, 'exceptionStatus')
    : {}

  const filterOptions = sortBy(Object.keys(countsByStatus)).map((status) => ({
    label: startCase(status),
    value: status
  }))

  const onlyExceptions = flattenDeep(
    formattedTargets
      .filter((ft) => ft.exceptions.length)
      .map((ft) => ft.exceptions.map((exception) => ({ ...exception, customer: ft.customer })))
  )

  const groupedExceptions = groupBy(onlyExceptions, 'exceptionStatus')

  const exceptionsFilterOptions = sortBy(Object.keys(groupedExceptions)).map((status) => ({
    label: startCase(status),
    value: status
  }))

  const tableData = useMemo(() => {
    return formattedTargets.filter((item) => {
      if (!item.customer) return Boolean(item.customer)
      const matchFilter = !storesFilterValue || item.status === storesFilterValue
      if (!storeInputValue) return matchFilter
      const formattedCustomerAddress = item.customer?.address ? Object.values(item.customer.address).join() : ''
      const addressMatch = formattedCustomerAddress.toLowerCase().includes(storeInputValue.toLowerCase())
      const customerNameMatch = item.customer?.name?.toLowerCase().includes(storeInputValue.toLowerCase())
      const erpMatch = item.customer?.id?.toString().toLowerCase().includes(storeInputValue.toLowerCase())
      const matchInput = addressMatch || customerNameMatch || erpMatch

      return matchInput && matchFilter
    })
  }, [storesFilterValue, storeInputValue, formattedTargets])

  const exceptionTableData = useMemo(() => {
    return onlyExceptions.filter((item) => {
      if (!item.customer) return Boolean(item.customer)
      const matchFilter = !exceptionsFilterValue || item.exceptionStatus === exceptionsFilterValue
      if (!exceptionInputValue) return matchFilter

      const formattedCustomerAddress = item.customer?.address ? Object.values(item.customer.address).join() : ''
      const addressMatch = formattedCustomerAddress.toLowerCase().includes(exceptionInputValue.toLowerCase())
      const customerNameMatch = item.customer?.name?.toLowerCase().includes(exceptionInputValue.toLowerCase())
      const erpMatch = item.customer?.id?.toString().toLowerCase().includes(exceptionInputValue.toLowerCase())
      const matchInput = addressMatch || customerNameMatch || erpMatch

      return matchInput && matchFilter
    })
  }, [tableData, exceptionInputValue, exceptionsFilterValue])

  const onStoresFilterChange = (e) => {
    e.stopPropagation()
    setStoresFilterValue(e.target.value)
  }

  const onStoresInputChange = (event) => {
    setStoreInputValue(event.currentTarget.value.trim())
  }

  const onExceptionsFilterChange = (e) => {
    e.stopPropagation()
    setExceptionsFilterValue(e.target.value)
  }

  const onExceptionsInputChange = (event) => {
    setExceptionInputValue(event.currentTarget.value.trim())
  }

  const downloadSkuData = () => {
    setDownloading(true)
    downloadSellInProgramSkuData({
      territoryId: currentTerritory.id,
      territoryName: currentTerritory.name,
      ...sellInProgram
    })
      .then(() => setDownloading(false))
      .catch(() => setDownloading(false))
  }

  const sendTerritoryEmail = () => {
    setEmailSending(true)
    sendSellInTerritoryRetailerEmail({
      territoryId: currentTerritory.id,
      sellInProgramId: sellInProgram.id
    })
      .then(() => {
        setShowConfirmEmailSend(false)
        setEmailSending(false)
      })
      .catch((err) => {
        setEmailSending(false)
        console.log(err)
      })
  }

  const brandStats = useMemo(() => {
    if (!isVolumeProgram) return

    return [
      {
        label: translate('storesView.brandStats.totalTargetedVolume'),
        value: sellInBrandTotals.totalTargetedVolume
      },
      {
        label: translate('storesView.brandStats.totalSuggestedQty'),
        value: sellInBrandTotals.totalSuggestedQty
      },
      {
        label: translate('storesView.brandStats.totalFinalQuantity'),
        value: sellInBrandTotals.totalFinalQuantity
      },
      {
        label: translate('storesView.brandStats.volumePerformance'),
        value: sellInBrandTotals.volumePerformance
      }
    ]
  }, [
    isVolumeProgram,
    sellInBrandTotals.totalSuggestedQty,
    sellInBrandTotals.totalFinalQuantity,
    sellInBrandTotals.totalTargetedVolume,
    sellInBrandTotals.volumePerformance
  ])

  const stats = [
    { label: translate('storesView.stats.storesTargeted'), value: storesTargeted },
    { label: translate('storesView.stats.countsByStatus.accepted'), value: countsByStatus.accepted || 0 },
    { label: translate('storesView.stats.countsByStatus.rejected'), value: countsByStatus.rejected || 0 },
    { label: translate('storesView.stats.countsByStatus.pending'), value: countsByStatus.pending || 0 },
    { label: translate('storesView.stats.exceptionsPending'), value: exceptionsPending },
    {
      label: translate('storesView.stats.acceptanceRate'),
      value: sellInBrandTotals.acceptanceRate
    }
  ]

  const [selectedException, setSelectedException] = useState()
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    setShowModal(Boolean(selectedException))
  }, [selectedException])

  const closeModal = () => {
    setSelectedException()
    setShowModal(false)
  }

  // Could not find program, go back to program list
  if (!sellInProgram && !isLoading) return <Navigate to=".." />

  const programIsEnded = isSellInEnded(sellInProgram?.endDate)
  const formatSellInNumber = (val, opts) => (typeof val === 'string' ? val : formatNumber(val, opts))

  return (
    <Container>
      <SellInEndedAlert isVolumeProgram={isVolumeProgram} endDate={sellInProgram?.endDate} />
      <StatsDash stats={stats} valueFormatter={formatSellInNumber} />
      {brandStats && <StatsDash stats={brandStats} valueFormatter={formatSellInNumber} />}
      {sellInBrandTotals.brandTotals && (
        <div>
          <BasicAccordion title="Brand totals" preExpand>
            <BrandTable brands={sellInBrandTotals.brandTotals} />
          </BasicAccordion>
        </div>
      )}

      {sellInBrandTotals.targetMatches && (
        <div className=" mt-4">
          <BasicAccordion title="Matched Territory Targets" preExpand>
            <TerritoryTargetsTable targets={sellInBrandTotals.targetMatches} />
          </BasicAccordion>
        </div>
      )}

      <TextFilter
        filterOptions={filterOptions}
        onFilterChange={onStoresFilterChange}
        onInputChange={onStoresInputChange}
        filterValue={storesFilterValue}
        placeholder="Stores filter"
      />
      <BasicAccordion title={'Stores'} preExpand>
        <Table
          data={tableData}
          columns={[
            {
              name: 'store',
              header: 'Store',
              body: {
                type: 'linkcell',
                labelGenFn: (record) => {
                  const hasException = record.exceptions?.find(({ exceptionStatus }) => exceptionStatus === 'pending')

                  return (
                    <div
                      onClick={() =>
                        fetchSector({
                          id: record.customerId,
                          type: SECTOR_LEVELS.CUSTOMER
                        })
                      }
                    >
                      <b>{record.customer.name}</b> ({record.customer.id})
                      <CustomerAddress customer={record.customer} />
                      <Pill color={sellInStatusColors[record.status]}>{record.status}</Pill>
                      {hasException && <Pill color={sellInProgram?.color}>Exception pending</Pill>}
                    </div>
                  )
                }
              }
            }
          ].concat(
            isVolumeProgram
              ? [
                  {
                    name: 'suggestedQty',
                    header: 'Sug. Qty'
                  },
                  {
                    name: 'finalQty',
                    header: 'Acc. Qty'
                  }
                ]
              : []
          )}
        />
      </BasicAccordion>
      {isVolumeProgram && (
        <>
          <SectionDivider />
          <ButtonGroup full>
            <Button full color={sellInProgram?.color} isLoading={downloading} onClick={downloadSkuData}>
              Download Sku Breakdown
            </Button>
            {countsByStatus.pending && !programIsEnded && (
              <Button
                full
                color={sellInProgram?.color}
                isLoading={emailSending}
                onClick={() => setShowConfirmEmailSend(true)}
              >
                Email SKU breakdown
              </Button>
            )}
          </ButtonGroup>
        </>
      )}

      <TextFilter
        filterOptions={exceptionsFilterOptions}
        onFilterChange={onExceptionsFilterChange}
        onInputChange={onExceptionsInputChange}
        filterValue={exceptionsFilterValue}
        placeholder="Exceptions filter"
      />

      <BasicAccordion title={'Exceptions'} preExpand>
        <ExceptionTable data={exceptionTableData} exceptionActionClick={setSelectedException} />
      </BasicAccordion>

      <ConfirmActionSheet
        title="Confirm Email"
        visible={showConfirmEmailSend}
        message="Are you sure you want to email a SKU breakdown to all pending stores in this territory?"
        confirmAction={sendTerritoryEmail}
        cancelAction={() => setShowConfirmEmailSend(false)}
        isLoading={emailSending}
      />

      {selectedException && showModal && (
        <SellInExceptionModal exception={selectedException} open={showModal} onHandleClose={closeModal} />
      )}
      <LoadingCard dataKey={programDataKey} />
    </Container>
  )
}

StoresView.propTypes = {
  connectedFetchSellInProgram: func
}

export default connect(null, {
  connectedFetchSellInProgram: fetchSellInProgram
})(StoresView)
