import React, { useContext } from 'react'
import * as Accordion from '@radix-ui/react-accordion'
import { arrayOf, shape, string } from 'prop-types'

import LangContext from 'context/LangContext'

import Icon from 'components/Icon'
import Pill from 'components/Pill'
import SAQDetailRow from 'components/SAQDetailRow'
import Status from 'components/Status'

const OrderRecap = () => {
  // ////////////////////////////////////////
  // ////////////////////////////////////////

  const PLACEHOLDER_ORDER_DATA = [
    {
      id: 'bkhf727wsxi8-qkg606riwry3zda0nokye18',
      date: 'Thu Oct 10 2024',
      placedBy: 'Francois Deguire',
      orderDetails: [
        {
          type: 'FMC',
          data: [
            {
              product: 'Product Name A 10x20',
              qty: 20
            },
            {
              product: 'Product Name B 8x25',
              qty: 20
            },
            {
              product: 'Product Name C 8x25',
              qty: 20
            },
            {
              product: 'Product Name D 8x25',
              qty: 20
            },
            {
              product: 'Product Name E 8x25',
              qty: 20
            }
          ]
        },
        {
          type: 'VAPE',
          data: [
            {
              product: 'Product Name A 10x20',
              qty: 20
            },
            {
              product: 'Product Name B 8x25',
              qty: 20
            },
            {
              product: 'Product Name C 8x25',
              qty: 20
            },
            {
              product: 'Product Name D 8x25',
              qty: 20
            },
            {
              product: 'Product Name E 8x25',
              qty: 20
            }
          ]
        }
      ]
    },
    {
      id: 'bkhf727wsxi8-qkg606riwry3zda0nokye24',
      date: 'Thu Oct 8 2024',
      placedBy: 'Francois Deguire',
      orderDetails: [
        {
          type: 'FMC',
          data: [
            {
              product: 'Product Name A 10x20',
              qty: 20
            },
            {
              product: 'Product Name B 8x25',
              qty: 20
            },
            {
              product: 'Product Name C 8x25',
              qty: 20
            },
            {
              product: 'Product Name D 8x25',
              qty: 20
            },
            {
              product: 'Product Name E 8x25',
              qty: 20
            }
          ]
        },
        {
          type: 'VAPE',
          data: [
            {
              product: 'Product Name A 10x20',
              qty: 20
            },
            {
              product: 'Product Name B 8x25',
              qty: 20
            },
            {
              product: 'Product Name C 8x25',
              qty: 20
            },
            {
              product: 'Product Name D 8x25',
              qty: 20
            },
            {
              product: 'Product Name E 8x25',
              qty: 20
            }
          ]
        }
      ]
    }
  ]

  // ////////////////////////////////////////
  // ////////////////////////////////////////

  const calculateTotalQty = (data) => data.reduce((sum, item) => sum + item.qty, 0)
  const calculateTotalQtyForAllTypes = (orderDetails) =>
    orderDetails.reduce((sum, orderType) => sum + calculateTotalQty(orderType.data), 0)

  const AccordionTrigger = ({ orderData }) => {
    const { translate } = useContext(LangContext)

    return (
      <Accordion.Trigger asChild>
        <tr className="group/item cursor-pointer select-none *:px-4 *:py-3 hover:bg-slate-50">
          <td>{orderData.date}</td>
          <td>{calculateTotalQtyForAllTypes(orderData.orderDetails)}</td>
          <td>
            <Status status="created" />
            <br />
            <Pill>{translate('app.acronyms.SAQ')}</Pill>
          </td>
          <td className="!p-0">
            <Icon icon="down-chevron" compact className="text-slate-500 group-data-[state=open]/item:rotate-180" />
          </td>
        </tr>
      </Accordion.Trigger>
    )
  }

  AccordionTrigger.propTypes = {
    orderData: shape({}).isRequired
  }

  const OrderByType = ({ order }) => {
    const totalQty = calculateTotalQty(order.data)

    return (
      <React.Fragment key={order.type}>
        <tr className="*:pb-2 *:pt-4 *:font-semibold *:text-slate-900">
          <td>{order.type}</td>
          <td className="text-right">{totalQty}</td>
        </tr>
        {order.data.map((data) => (
          <tr key={data.product} className="*:py-1 *:text-slate-700">
            <td>{data.product}</td>
            <td className="text-right">{data.qty}</td>
          </tr>
        ))}
      </React.Fragment>
    )
  }

  OrderByType.propTypes = {
    order: shape({
      type: string,
      data: arrayOf(shape({}))
    }).isRequired
  }

  const AccordionContent = ({ orderData }) => {
    return (
      <Accordion.Content asChild className="overflow-hidden">
        <tr className="border-b border-slate-900/10 *:p-4">
          <td colSpan="4" className="w-full">
            <div>
              <SAQDetailRow label="Order #" content={<p>{orderData.id}</p>} />
              <SAQDetailRow label="Placed by" content={<p>{orderData.placedBy}</p>} />
              {/* {isPo98Order && order.updatedAt ? (
            <SAQDetailRow
              label={translate('common.updatedAt')}
              content={<p>{new Date(order.updatedAt).toDateString()}</p>}
            />
          ) : null}
          {isPo98Order && order.status !== 'created' && (
            <SAQDetailRow
              label={translate('app.statusUpdatedAt')}
              content={<p>{new Date(order.updatedAt).toDateString()}</p>}
            />
          )}
          {order.confirmationDate && (
            <SAQDetailRow
              label={translate('common.confirmedAt')}
              content={<p>{new Date(order.confirmationDate).toDateString()}</p>}
            />
          )}
          {isPo98Order && order.po98PoNumber && (
            <SAQDetailRow label={translate('app.P0Number')} content={<p>{order.po98PoNumber}</p>} />
          )}
          {order.purchaseOrder && (
            <SAQDetailRow
              label={translate('app.purchaseOrder') + ':'}
              content={<p>{order.purchaseOrder}</p>}
            />
          )} */}
              <div className="mt-4 rounded bg-slate-50 p-4">
                <table className="w-full text-sm">
                  <thead>
                    <tr className="*:font-medium *:uppercase *:text-slate-500">
                      <th className="text-left">Product</th>
                      <th className="text-right">Qty</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderData.orderDetails.map((order) => (
                      <OrderByType key={order.type} order={order} />
                    ))}
                    <tr className="*:pb-2 *:pt-4 *:font-semibold *:text-slate-900">
                      <td>Total</td>
                      <td className="text-right">{calculateTotalQtyForAllTypes(orderData.orderDetails)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </td>
        </tr>
      </Accordion.Content>
    )
  }

  AccordionContent.propTypes = {
    orderData: shape({}).isRequired
  }

  return (
    <Accordion.Root type="single" collapsible asChild>
      <table className="w-full rounded-md ring-1 ring-slate-900/10">
        <thead>
          <tr className="border-b border-slate-900/10 bg-slate-100 *:px-4 *:py-3 *:text-left *:font-normal *:text-slate-500">
            <th className="rounded-tl-md">Date</th>
            <th>Qty.</th>
            <th>Status</th>
            <th className="rounded-tr-md content-['']" />
          </tr>
        </thead>
        <tbody className="group/table divide-y divide-slate-900/10 overflow-hidden">
          {PLACEHOLDER_ORDER_DATA.map((order) => (
            <Accordion.Item value={order.id} key={order.id} asChild>
              <>
                <AccordionTrigger orderData={order} />
                <AccordionContent orderData={order} />
              </>
            </Accordion.Item>
          ))}
        </tbody>
      </table>
    </Accordion.Root>
  )
}

export default OrderRecap
